import React from 'react'
import logo from '../../assets/logo.png';
import './Home.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate  } from "react-router-dom";

export const Home = () => {


  let navigate = useNavigate(); 
  const routeChange = (e) =>{ 
    if(e.target.id === "beyondearthonline") { 
      window.open('https://beyondearthonline.io/');
    }
    if(e.target.id === "galacticmarketplace") {
      navigate('/galacticmarketplace');
    }
    if(e.target.id === "documents") {
      navigate('/documents');
    }
    if(e.target.id === "galacticlabs") {
      navigate('/galacticlabs');
    }
    
  }

  
  return (
    <div className="Home">
      <div className="Home-header">
        <img src={logo} className="Home-logo" alt="logo" />
        <button onClick={routeChange} className='glButton' id = 'galacticlabs' data-toggle="tooltip" data-placement="top" title="About Galactic Labs"/>
        <p className= 'Home-title'>
          GALACTIC LABS
        </p>
          <button  id='beyondearthonline'  onClick= {routeChange}  className = 'button-link link1' data-toggle="tooltip" data-placement="top" title="Beyond Earth Website" />
          <button id='galacticmarketplace' onClick= {routeChange} className = 'button-link link2' data-toggle="tooltip" data-placement="top" title="Galactic Marketplace"/>
          <button id='documents' onClick= {routeChange} className = 'button-link link3' data-toggle="tooltip" data-placement="top" title="Documentation" />
      </div>
    </div>
  )
}


