import React, { useState } from 'react'
import { useNavigate  } from "react-router-dom";
import {Carousel} from '3d-react-carousal';
import './GalacticLabs.css';
import Marquee from 'react-fast-marquee';
import Partner1 from '../../assets/partner.png';
import Partner2 from '../../assets/partner2.png';
import Partner3 from '../../assets/partner3.png';
import Partner4 from '../../assets/partner4.png';
import Partner5 from '../../assets/partner5.png';
import Partner6 from '../../assets/partner6.png';
import shops from '../../assets/SHOPS.png';
import realEstate from '../../assets/realEstate.png';
import alienfrens from "../../assets/CALLING_ALL_ALIEN_FRENS.png";
import moonbirds from "../../assets/CALLING_ALL_MOONBIRDS.png";
import perilouspetz from "../../assets/CALLING_ALL_PERILOUS_PETZ.png";
import radapes from "../../assets/CALLING_ALL_RAD_APES.png";
import morries from "../../assets/CALLING_ALL_MORRIES.png";
import crankycritters from "../../assets/CALLING_ALL_CRANKY_CRITTERS.png";
import supremeskulls from "../../assets/CALLING_ALL_SUPREME_SKULLS.png";
import nfh from "../../assets/CALLING_ALL_NFH.png";
import nekkidparadise from "../../assets/CALLING_ALL_NEKKID_PARADISE.png";
import foxyfam from "../../assets/CALLING_ALL_FOXY_FAM.png";
import metaguardians from "../../assets/CALLING_ALL_METAGUARDIANS.png";
import lambduh from "../../assets/CALLING_ALL_LAMB_DUH.png";
import coolcats from "../../assets/BEO_WELCOMES_COOLCATS.png";
import llama from "../../assets/BEO_WELCOMES_llama.png";
import zenacademy from "../../assets/BEO_WELCOMES_ZEN_ACADEMY.png";
import llamaversename from '../../assets/llamaverseName.png';
import WPCName from '../../assets/WPCName.png';
import gosuname from '../../assets/gosuName.png';
import rethinkable from '../../assets/rethinkable.png';
import whitelistping from '../../assets/whitelistping.png';
import "./particle.scss";




export const GalacticLabs = () => {

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    navigate('/');
  }

  const [isHoveringShop, setIsHoveringShop] = useState(false);
  const [isHoveringEstate, setIsHoveringEstate] = useState(false);
  const [isHoveringBillboard, setIsHoveringBillboard] = useState(false);

  const handleMouseOver = (e) => {
    if (e.target.id === 'shop' ) {
        setIsHoveringShop(true);
    }
    if (e.target.id === 'estate' ) {
        setIsHoveringEstate(true);
    }
    if (e.target.id === 'billboard' ) {
        setIsHoveringBillboard(true);
    }
    
  };

  const handleMouseOut = (e) => {

    if (e.target.id === 'shop' ) {
        setIsHoveringShop(false);
    }
    if (e.target.id === 'estate' ) {
        setIsHoveringEstate(false);
    }
    if (e.target.id === 'billboard' ) {
        setIsHoveringBillboard(false);
    }
  };


  let slides = [
    <img  style={{width: '50%'}} src={llama} alt="1" />,
    <img  style={{width: '50%'}} src={moonbirds}  alt="2" />  ,
    <img  style={{width: '50%'}} src={morries}  alt="3" />  ,
    <img  style={{width: '50%'}} src={perilouspetz}  alt="4" />  ,
    <img  style={{width: '50%'}} src={alienfrens}  alt="5" />,
    <img  style={{width: '50%'}} src={crankycritters}  alt="6" />,
    <img  style={{width: '50%'}} src={supremeskulls}  alt="7" />,
    <img  style={{width: '50%'}} src={nfh}  alt="8" />,
    <img  style={{width: '50%'}} src={nekkidparadise}  alt="9" />,
    <img  style={{width: '50%'}} src={foxyfam}  alt="10" />,
    <img  style={{width: '50%'}} src={metaguardians}  alt="11" />,
    <img  style={{width: '50%'}} src={coolcats}  alt="12" />,
    <img  style={{width: '50%'}} src={llama} alt="13" />,
    <img  style={{width: '50%'}} src={lambduh} alt="14" />,
    <img  style={{width: '50%'}} src={radapes} alt="15" />,
    <img  style={{width: '50%'}} src={zenacademy} alt="16" />   ];

    let slidesMobile = [
        <img  style={{width: '100%'}} src={llama} alt="1" />,
        <img  style={{width: '100%'}} src={moonbirds}  alt="2" />  ,
        <img  style={{width: '100%'}} src={morries}  alt="3" />  ,
        <img  style={{width: '100%'}} src={perilouspetz}  alt="4" />  ,
        <img  style={{width: '100%'}} src={alienfrens}  alt="5" />,
        <img  style={{width: '100%'}} src={crankycritters}  alt="6" />,
        <img  style={{width: '100%'}} src={supremeskulls}  alt="7" />,
        <img  style={{width: '100%'}} src={nfh}  alt="8" />,
        <img  style={{width: '100%'}} src={nekkidparadise}  alt="9" />,
        <img  style={{width: '100%'}} src={foxyfam}  alt="10" />,
        <img  style={{width: '100%'}} src={metaguardians}  alt="11" />,
        <img  style={{width: '100%'}} src={coolcats}  alt="12" />,
        <img  style={{width: '100%'}} src={llama} alt="13" />,
        <img  style={{width: '100%'}} src={lambduh} alt="14" />,
        <img  style={{width: '100%'}} src={radapes} alt="15" />,
        <img  style={{width: '100%'}} src={zenacademy} alt="16" />   ];    

  return (
    <div className='galactic-main' style={{textAlign: 'center'}}>
        <div class="page-bg"></div>

        <div class="animation-wrapper">
        <div class="particle particle-1"></div>
        <div class="particle particle-2"></div>
        <div class="particle particle-3"></div>
        <div class="particle particle-4"></div>
        <button className='homeButton' onClick={routeChange} style={{}}>
                Home
        </button>
        </div>
        <div className='galactic-container-one' >
            <h1 className='headerOne'>
                What is <span className='glow'> Galactic Labs</span>?
            </h1>
            <div className='galactic-mission'>
                <h1 className='headerTwo'>
                    Misson
                </h1>
                <p style={{fontFamily: 'Bungee', fontSize: '20px' }}>
                    Galactic Labs seeks to create a productive networking environment for people working in web 3, as we know the importance of community and quality connections in the NFT space. 
                </p>
                <p style={{fontFamily: 'Bungee', fontSize: '20px' }}>
                    Galactic Labs will also be partnering with as many legitimate projects as possible within the entire Web3 / NFT ecosystem to provide value to investors and projects on both sides.
                </p>
            </div>
            </div>
            <div className='galactic-body'>
                <div style={{textAlign: 'center'}}>
                    <h1 className='headerOne'>
                        Galactic Marketplace
                    </h1>
                </div>

                <div className='galactic-container-two'>
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                        <img id='shop' onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} src={shops} style={{width: '90%', height: '450px', marginRight: '3rem'}} />
                        {isHoveringShop && (
                            <div style={{marginTop: '1rem', padding: '1rem 1rem 0 1rem', textAlign: 'center', background: 'gray', borderRadius: '10px', width: '90%'}}>
                                <p>Custom shops will be offered to players in the future. Custom shops will allow people to sell custom items inside the game. Owners will be able to offer multiple services to players in the game. A monthly fee will be charged to the owners.</p>
                            </div>
                            )}
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', textAlign: 'left'}}>
                        <img  id='billboard' onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} src={realEstate} style={{width: '90%', marginRight: '3rem'}} />
                        {isHoveringBillboard && (
                            <div style={{marginTop: '1rem', padding: '1rem 1rem 0 1rem', textAlign: 'center', background: 'gray', borderRadius: '10px', width: '90%'}}>
                                <p>Build your brand by renting one of our billboards in Vega City. Billboards owners will be charged monthly.</p>
                            </div>
                            )}
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                        <img  id='estate' onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} src={realEstate} style={{width: '90%'}} />
                        {isHoveringEstate && (
                            <div style={{marginTop: '1rem', padding: '1rem 1rem 0 1rem', textAlign: 'center', background: 'gray', borderRadius: '10px', width: '90%'}}>
                                <p>Members that own land in Vega City will have the ability to host events, rent out billboards, sell NFTs, & sell custom items from their plot. We will begin the land sale with the land plot shown below that says for sale.</p>
                            </div>
                            )}
                    </div>

                </div>
                <div className='galactic-container-two-mobile'>
                    <img src={shops} style={{width: '80%', height: '80%', marginBottom: '3rem'}} />
                    <img src={realEstate} style={{width: '80%', height: '100%', marginBottom: '3rem'}} />
                    <img src={realEstate} style={{width: '80%', height: '100%'}} />
                </div>

                {/* <h1 className='headerTwo'>Whitelist Spots</h1>
                <p style={{fontFamily: 'Roboto', fontSize: '20px' }}>
                    We will offer whitelist spots to projects recommended by our holders. 
                </p> */}
                <div style={{textAlign: 'center'}}>
                    <h1 className='headerTwo'>Game Items</h1>
                    <p style={{fontFamily: 'Roboto', fontSize: '20px', marginBottom: '6rem' }}>
                        Vehicles, characters, mounts, decorations, and other game items will be available on our marketplace and will be limited supply & seasonal.
                    </p>
                    <h1 className='headerTwo'>Character Integrations </h1>
                    <p style={{fontFamily: 'Roboto', fontSize: '20px' }}>
                        Get your NFT collection integrated in our MMORPG game and allow your holders to play & earn GOLD from playing. 
                    </p>
                    <div className='carousel' style={{margin: '5rem 0'}}>
                        <Carousel slides={slides} autoplay={true} interval={7000}/>
                    </div>
                    <div className='mobileCarousel' style={{margin: '5rem 0'}}>
                        <Carousel slides={slidesMobile} autoplay={true} interval={7000}/>
                    </div>
                </div>
                <div className='slider'>
            <Marquee speed={30} gradient={false} direction={"right"} style={{width:'100%'}}>
                        <div className='partners-ribbon'>
                            <img style={{width: '6%'}} src={llamaversename} alt="llamaversename"/>
                            <img style={{width: '2%'}} src={WPCName} alt="WPCName"/>
                            <img style={{width: '3%'}} src={gosuname} alt="gosuName"/>
                            <img style={{width: '3%'}} src={rethinkable} alt="rethinkable"/>
                            <img style={{width: '3%'}} src={whitelistping} alt="whitelistping"/>
                        </div>
                </Marquee>
                <Marquee speed={50} gradient={false} direction={"left"} style={{width:'100%'}}>
                        <div className='partners'>
                            <img src={Partner1} alt="Partner1"/>
                            <img src={Partner2} alt="Partner2"/>
                            <img src={Partner3} alt="Partner3"/>
                            <img src={Partner4} alt="Partner4"/>
                            <img src={Partner5} alt="Partner5"/>
                            <img src={Partner6} alt="Partner6"/>
                        </div>
                </Marquee>
                <Marquee speed={30} gradient={false} direction={"right"} style={{width:'100%',height:'20%'}}>
                        <div className='partners-ribbon'>
                            <img style={{width: '6%'}} src={llamaversename} alt="llamaversename"/>
                            <img style={{width: '2%'}} src={WPCName} alt="WPCName"/>
                            <img style={{width: '3%'}} src={gosuname} alt="gosuName"/>
                            <img style={{width: '3%'}} src={rethinkable} alt="rethinkable"/>
                            <img style={{width: '3%'}} src={whitelistping} alt="whitelistping"/>
                        </div>
                </Marquee>
            </div>

                
            </div>
  </div>
  )
}


