import React from 'react'
import { useNavigate  } from "react-router-dom";

export const GalacticMarketplace = () => {

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    navigate('/');
  }

  return (
    <div style={{textAlign: 'center', color: 'white', fontFamily: 'Bungee'}}>
    <h1>
      Galactic Marketplace
    </h1>
    <button className ='homeButton' onClick={routeChange}>
      Back
    </button>
  </div>
  )
}


