import React from 'react'
import { useNavigate  } from "react-router-dom";
import '../BeyondEarthOnline.css';
import logo from '../../../assets/logoBEO.png';
import {BrowserRouter, Link} from "react-router-dom";

export const NavbarBEO = () => {

    let navigate = useNavigate(); 
    const routeChange = () =>{ 
      navigate('/');
    }

    
  
    return (
      <div className='navbarBEO'>
        <div className='left-container'>
          <img src={logo}/>
          <ul >
                  <li><Link to='/' style={{textDecoration: 'none', color: 'white'}}>HOME</Link></li>
                  <li><Link to='/contact' style={{textDecoration: 'none', color: 'white'}}>CHARACTER MINT</Link></li>
                  <li><Link to='/contact' style={{textDecoration: 'none', color: 'white'}}>PLAY</Link></li>
                  <li><Link to='/contact' style={{textDecoration: 'none', color: 'white'}}>BUILD</Link></li>
                  <li><Link to='/contact' style={{textDecoration: 'none', color: 'white'}}>WIKI</Link></li>
                  <li><Link to='/contact' style={{textDecoration: 'none', color: 'white'}}>TOKEN</Link></li>
          </ul>
        </div>
        <div className='right-container'>
          
        </div>


      </div>
    )
  }
  