import React from 'react'
import { useNavigate  } from "react-router-dom";
import './BeyondEarthOnline.css';
import video from '../../assets/bg-teaser.mp4';
import { NavbarBEO } from './components/NavbarBEO';

export const BeyondEarthOnline = () => {

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    navigate('/');
  }

  return (
    <div>
      <div className='beoBody' style={{textAlign: 'center'}}>
        <NavbarBEO />
      {/* <video autoPlay loop muted id='video' resizeMode={"cover"}>
        <source src = {video} type='video/mp4' />
      </video> */}
      <h1 className='text-style'>
            BeyondEarthOnline
          </h1>
          <button onClick={routeChange}>
            Home
          </button>
      </div>
    </div>
  )
}


