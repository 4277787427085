import './App.css';
import { Home } from './pages/Home/Home';
import { BeyondEarthOnline } from './pages/BeyondEarthOnline/BeyondEarthOnline';
import { GalacticMarketplace } from './pages/GalacticMarketplace/GalacticMarketplace';
import { Documents } from './pages/Documents/Documents';
import { GalacticLabs } from './pages/GalacticLabs/GalacticLabs';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {

  document.body.style = 'background: black;';
  
  return (
    <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/beyondearthonline' element={<BeyondEarthOnline />}/>
          <Route path='/galacticmarketplace' element={<GalacticMarketplace />}/>
          <Route path='/documents' element={<Documents />}/>
          <Route path='/galacticlabs' element={<GalacticLabs />}/>
        </Routes>
    </BrowserRouter>

  );
}

export default App;
